@value tablet from "breakpoints.module.css";

.container {
    position: relative;
    display: none;
}

@media tablet {
    .container {
        display: flex;
    }
}

.burgerContainer {
    user-select: none;
    cursor: pointer;
    transition: 1s ease;
}

.burgerContainerOpen {
    left: 2rem;
    top: 2rem;
    z-index: 20;
    position: fixed;
    cursor: pointer;
    padding: 0 30px 20px 0;
}

.burger, .arrowBack {
    width: 15px;
    height: 12px;
    position: relative;
    display: inline-block;
    z-index: 20;
}

.burger span, .arrowBack span {
    background-color: var(--primary-text-color);
    position: absolute;
    border-radius: 2px;
    transition: .4s cubic-bezier(.25, .54, .73, .61);
    height: 2px;
    width: 15px;
    display: block;
}

.burger span {
    margin-bottom: 5px;
}

.burger span:last-child {
    margin-bottom: 0;
}

.burger span:nth-child(2) {
    top: 5px;
    left: 0;
}

.burger span:nth-child(3) {
    bottom: 0;
}

.arrowBack {
    transform: rotate(90deg);
}

.arrowBack span:nth-child(1) {
    display: none;
}

.arrowBack span:nth-child(2) {
    left: -7px;
    top: 5px;
    width: 13px;
    transition: .3s cubic-bezier(.25, .54, .73, .61);
    transform: rotate(45deg);
    transition-delay: 50ms;
    animation: fadeIn 2s;
}

.arrowBack span:nth-child(3) {
    left: 1px;
    top: 5px;
    width: 13px;
    transition: .3s cubic-bezier(.25, .54, .73, .61);
    transform: rotate(-45deg);
    transition-delay: 100ms;
    animation: fadeIn 2s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fullScreenMenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--black);
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    z-index: 15;
    animation: fadeIn 0.5s;
}

.menuTitle {
    margin: 30px auto 60px;
    text-transform: uppercase;
    font-size: 1.1rem;
}