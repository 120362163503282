@value mobile from "breakpoints.module.css";

.arrowDownBox {
    position: absolute;
    bottom: 1.2rem;
    left: 2.2rem;
    cursor: pointer;
}

@media mobile {
    .arrowDownBox {
        width: 20px;
        bottom: 3rem;
        left: 1rem;
    }
}