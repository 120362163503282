@value tablet, mobile from "breakpoints.module.css";

.mainPicBox {
    position: absolute;
    top: 50%;
    left: 43%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    width: 650px;
    max-width: 55%;
}

@media tablet {
    .mainPicBox {
        top: 45%;
        left: 50%;
        transform: translate(-50%, -45%);
        max-width: 70%;
    }
}

@media mobile {
    .mainPicBox {
        width: 100%;
        max-width: calc(100% - 32px);
    }
}