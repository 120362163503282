.languageSwitcher {
    display: flex;
    align-items: center;
}

.switcher {
    display: flex;
    flex-direction: column;
    position: relative;
}

@keyframes jump {
    0% {
        transform: rotate(-540deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

.arrow {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 7px solid white;
    transition: transform 0.3s ease;
    display: inline-block;
    margin-left: 5px;
    transform: rotate(-360deg);
    animation: jumpBack 1s ease;
}

@keyframes jump {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(-540deg);
    }
}

.arrow.open {
    transform: rotate(-540deg);
    animation: jump 1s ease;
}

.switcher__options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 10;
}

.switcherBtn {
    cursor: pointer;
    font-size: 1rem;
    padding: 8px 6px 8px 8px;
    transition: color 0.3s;
    text-transform: capitalize;
    color: var(--primary-text-color);
    border: 1px solid var(--blue);
    display: flex;
    align-items: baseline;
    background-color: var(--black);
}

.switcherBtn:first-child {
    border-radius: 18px 0 0 0;
}

.switcherBtn:hover {
    color: var(--blue);
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


.switcher__options .switcherBtn:first-child {
    border-radius: unset;
    border-top: none;
    border-bottom: none;
    opacity: 0;
    animation: fadeIn 0.5s ease-in forwards;
}

.switcher__options .switcherBtn:last-child {
    border-radius: 0 0 24px 0;
    animation: fadeIn 1s ease-in forwards;
}

.active {
    outline: none;
    background: linear-gradient(black, black) padding-box,
    var(--gradient) border-box;
    border: 1px solid transparent;
    transition: 0.4s ease;
}


