.box {
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #8F29FD;
    padding: 4px 8px;
}

.box h4 {
    text-transform: uppercase;
    font-size: 18px;
    line-height: 24px;
    color: #8F29FD;
    white-space: nowrap;
}