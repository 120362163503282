@value mobile, tablet, desktop from "breakpoints.module.css";

.works__section {
    height: 100%;
}

.works {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    color: var(--black);
    margin-bottom: 20px;
}

.worksTitle {
    margin-bottom: 42px;
    text-transform: uppercase;
    font-size: 1.3rem;
}

.works__item {
    background-color: var(--primary-text-color);
    border-radius: 8px;
    padding: 20px 20px 40px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0.5px 0.5px 5px 5px rgba(0, 0, 0, 0.2);
}

.item__img {
    margin-bottom: 24px;
    max-width: 424px;
    width: 100%;
    height: 275px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
}

.item__imgLoading {
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
    background-size: 1000px 640px;
    width: 100%;
    max-width: 424px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.item__imgAnimated {
    animation: fadeIn 3s ease;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }
    100% {
        background-position: 468px 0
    }
}


.item__img_popup {
    height: 300px;
    position: relative;
}

.item__title {
    font-size: 20px;
    margin-bottom: 8px;
    text-transform: uppercase;
}

.item__subtitle {
    font-size: 18px;
    margin-bottom: 20px;
}

.item__button {
    border-radius: 4px;
    background: linear-gradient(var(--primary-text-color), var(--primary-text-color)) padding-box, var(--gradient) border-box;
    border-image: var(--gradient) 0;
    border-width: 1px;
    border-style: solid;
    padding: 12px 24px;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 1rem;
}

.item__button span {
    background-image: var(--gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.item__button:hover {
    background: var(--gradient) padding-box, var(--gradient) border-box;
}

.item__button:hover span {
    background-image: var(--primary-text-color);
    background-color: var(--primary-text-color);
}

.works__otherBtn {
    color: var(--primary-text-color);
    margin: 24px 0 24px auto;
    display: flex;
    align-items: center;
    font-size: 1rem;
}

.works__otherIcon_hide {
    transform: rotate(180deg);
    margin-top: -5px;
}

.works__otherBtn p {
    margin-right: 8px;
}

.works__otherIcon {
    width: 24px;
    height: 24px;
}


.item__popup {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.item__text, .item__info {
    margin-bottom: 16px;
    text-align: left;
    font-size: 16px;
}

.item__info {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 36px;
}

.item__info div {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.popup__closeIcon {
    width: 11px;
    height: 11px;
    margin: 0 0 20px auto;
    display: flex;
    cursor: pointer;
}

@media tablet {
    .works {
        grid-template-columns: 1fr 1fr;
    }

    .worksTitle {
        margin-bottom: 34px;
    }

    .item__img_popup {
        height: 250px;
        width: 100%;
    }
}

@media mobile {
    .works {
        grid-template-columns: 1fr;
    }

    .worksTitle {
        margin-bottom: 28px;
        font-size: 1.25rem;
    }

    .works__item {
        padding: 20px 20px 34px;
    }

    .item__img, .item__imgLoading {
        height: 220px;
    }

    .item__title {
        margin-bottom: 12px;
    }

    .item__button {
        font-size: 1rem;
    }

    .item__button:hover {
        background: linear-gradient(var(--primary-text-color), var(--primary-text-color)) padding-box, var(--gradient) border-box;
    }

    .item__button:hover span {
        background-image: var(--gradient);
    }

    .item__img_popup {
        height: 240px;
        display: flex;
        justify-content: center;
    }

    .item__info {
        margin-bottom: 20px;
    }

    .works__otherBtn {
        margin: 24px 0 24px auto;
    }
}
