@value tablet, mobile from "breakpoints.module.css";

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 2.2rem;
    position: sticky;
    top: 0;
    z-index: 100;
    background: var(--black);
    -webkit-transition: height 0.3s;
    -moz-transition: height 0.3s;
    transition: height 0.3s;
}

.logo {
    height: 2.05rem;
    width: 11.2rem;
    display: flex;
    cursor: pointer;
}

.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header__actions {
    display: flex;
    align-items: center;
    gap: 40px;
}

@media tablet {
    .visibilityWrapper {
        display: none;
    }

    .navigation {
        width: 100%;
        justify-content: end;
        margin-right: 1.5rem;
    }
}

@media mobile {
    .header {
        padding: 1.16rem 1rem;
    }

    .header__actions {
        gap: 26px;
    }
}
