@value mobile, tablet from "breakpoints.module.css";

#sectionContact {
    display: flex;
    justify-content: center;
    height: 100%;
}

.sectionContainer {
    padding: 215px 0 135px;
}

.animatedForm {
    width: 100%;
}

@media tablet {
    .sectionContainer {
        width: 90%;
        justify-content: center;
        display: flex;
    }
}

@media mobile {
    .sectionContainer {
        width: 100%;
        padding: 80px 0;
    }
}

