@value mobile from "breakpoints.module.css";

.menu {
    color: var(--primary-text-color);
}

.menuList {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
}

.menuItem {
    margin-right: 2.6rem;
}

.menuItem:last-child {
    margin-right: 1.16rem;
}

.menuLink {
    text-decoration: none;
    color: var(--primary-text-color);
    font-size: 1rem;
    cursor: pointer;
}

.menuLink:hover,
.menuLink:focus,
.active {
    color: var(--blue);
}

.menuList__column {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.menuList__column .menuItem {
    margin: 0 0 1rem 0;
}

.menuList__column .menuItem:last-child {
    margin-right: 0;
}

@media mobile {
    .menuLink {
        font-size: 1.1rem;
    }
}
