@keyframes slideInFromLeft {
    from {
        transform: translate(-100%, -40%);
        opacity: 0;
    }
    to {
        transform: translate(0, -40%);
        opacity: 1;
    }
}

@keyframes slideInFromRight {
    from {
        transform: translate(100%, -60%);
        opacity: 0;
    }
    to {
        transform: translate(0%, -60%);
        opacity: 1;
    }
}


.slideRight {
    animation: slideInFromLeft 1s ease-out forwards;
    animation-delay: 0.5s;
}

.slideLeft {
    animation: slideInFromRight 1s ease-out forwards;
    animation-delay: 0.5s;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #282c34;
    z-index: 100;
    position: fixed;
    width: 100%;
    color: #fff;
}

.background {
    background-color: #282c34;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100vh;
    font-family: "Inter", sans-serif;
}

.container {
    width: 1280px;
    height: 720px;
    position: relative;
    border-radius: 40px;
    background-color: black;
    padding: 40px;
}

.innerContent {
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    width: auto;
    height: 100%;
}

.header {
    display: flex;
    width: 100%;
    justify-content: stretch;
    height: 80px;
}

.header__company {
    height: 100%;
    background-color: black;
    border-bottom-right-radius: 40px;
    color: white;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header__company h1 {
    font-size: 27px;
    text-transform: uppercase;
    width: 50%;
}

.header__teamSwitcher {
    border: 1px solid #ffffff;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    max-width: 148px;
    width: 50%;
    height: 58px;
    margin-right: 10px;
}

.switcher__text, .loginSwitcher__text {
    font-size: 16px;
    color: #85858B;
}

.switcher__text {
    margin-left: 30px;
}

.loginSwitcher__text {
    margin-left: 60px;
}


.switcher__button {
    background-color: #716ae2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    width: 40px;
    height: 40px;
}

.switcher__button svg {
    width: 20px;
    height: 20px;
}

.header__decoration {
    display: flex;
    justify-content: space-between;
    width: fit-content;
    flex: 1.5;
}

.header__decoration svg {
    width: 40px;
    height: 40px;
}

.header__decoration .decorationEnd {
    transform: scaleX(-1);
}

.header__signUpActions {
    height: 80px;
    background-color: black;
    border-bottom-left-radius: 40px;
    color: white;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header__loginSwitcher {
    border: 1px solid #ffffff;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 94%;
    height: 58px;
}

.loginSwitcher__button {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 16px;
    border-radius: 30px;
    height: 100%;
    width: 50%;
}

.mainText {
    position: absolute;
    top: 50%;
    left: 8%;
    transform: translateY(-40%);
}

.mainText h2 {
    font-size: 50px;
    color: white;
    margin-bottom: 20px;
}

.mainText span {
    font-size: 17px;
    color: white;
}

.infoBlocks {
    position: absolute;
    top: 60%;
    right: 6%;
    transform: translateY(-60%);
    width: 24%;
}

.infoBlocks__info {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 28px 38px;
    border: 1px solid #ffffff;
    color: #ffffff;
    border-radius: 44px;
    overflow: hidden;
    backdrop-filter: blur(6px);
    background-color: transparent;
}

.infoBlocks__info h3 {
    font-size: 18px;
    margin-bottom: 20px;
}

.infoBlocks__info div {
    font-size: 14px;
    margin-bottom: 20px;
}

.infoBlocks__button {
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 24px;
    overflow: hidden;
    font-size: 14px;
    padding: 14px 32px;
    display: flex;
    align-items: center;
    background-color: transparent;
    width: fit-content;
}

.swipeBox {
    position: relative;
    margin-top: 100px;
    border: 1px solid #ffffff;
    border-radius: 44px;
    overflow: hidden;
    display: flex;
    align-items: center;
    font-size: 16px;
    width: 95%;
    height: 58px;
}

.swipeBox__button {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    width: 40px;
    height: 40px;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
}

.swipeBox__button svg {
    width: 20px;
    height: 20px;
}

.swipeBox span {
    margin: 0 auto;
    color: #ffffff;
}

.bottom {
    width: 40%;
    display: flex;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    height: 80px;
    justify-content: center;
    align-items: end;
}

.bottom__action {
    width: 80%;
    background-color: black;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    overflow: hidden;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeIn 1s ease;
}

.bottom__decorationLeft {
    display: flex;
    height: 40px;
    width: 40px;
}

.bottom__decorationLeft .bottom__decorationLeft_right {
    transform: scaleX(-1);
}

.bottom__decorationLeft {
    transform: rotate(180deg);
}

.bottom__decorationRight {
    transform: rotate(-90deg);
}

.bottom__actionBtn {
    border-radius: 44px;
    background-color: #857ef6;
    color: #ffffff;
    font-size: 18px;
    padding: 24px;
    height: 68px;
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 94%;
    margin-top: 10px;
    border: none;
    justify-content: center;
    font-weight: 600;
}