@value tablet, mobile from "breakpoints.module.css";

.team {
    padding: 80px 2.2rem;
    height: 100%;
    display: flex;
    overflow-x: hidden;
}

.backgroundContainer {
    position: relative;
    width: 100%;
    height: 100%;
}

.backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.overlayContent {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    margin-bottom: -15px;
}


.foregroundImage {
    max-width: 100%;
    height: auto;
}

.titleBox {
    background: black;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    display: block;
    padding: 4px 12px;
}

.title {
    text-transform: uppercase;
    font-size: 32px;
    font-weight: 500;
}


@media mobile {
    .sectionTeam {
        height: auto;
    }

    .team {
        padding: 80px 0 0 0;
        height: 75vh;
        height: 75svh;
    }

    .overlayContent {
        margin-bottom: -10px;
        height: 100%;
    }

    .titleBox {
        top: 0;
        bottom: unset;
        margin-top: -10px;
    }

    .title {
        font-size: 16px;
        font-weight: 400;
        white-space: nowrap;
    }
}