@value mobile, tablet, desktop from "breakpoints.module.css";

.offers {
    display: flex;
    flex-direction: column;
    white-space: pre-line;
}

.offers__section {
    height: 100%;
    margin-bottom: 40px;
}

.offersTitle {
    margin: 0 0 42px 3px;
    text-transform: uppercase;
    font-size: 1.3rem;
}

.offersList {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(auto-fit, minmax(222px, 1fr));
    grid-row: 1;
    margin-bottom: 40px;
}


.offersList__item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    min-width: 222px;
    border: 1px solid var(--primary-text-color);
    text-transform: uppercase;
    border-radius: 4px;
    box-sizing: border-box;
}

.offersList__item:nth-child(1),
.offersList__item:nth-child(3) {
    background: linear-gradient(black, black) padding-box,
    var(--gradient) border-box;
    border: 1px solid transparent;
}

/*refactor*/
/*.offersList li:nth-child(2) .offersList__item,*/
/*.offersList li:nth-child(4) .offersList__item{*/
/*    background: transparent;*/
/*    border: 1px solid var(--primary-text-color);*/
/*}*/


.offersList__item p {
    max-width: 70%;
    text-align: center;
    font-size: 1.2rem;
    word-spacing: 1000px;
}

.offersList__item:last-child p {
    word-spacing: 0;
}

@media desktop {
    .offersList {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 0;
        justify-content: center;
        gap: 40px;
    }

    .offersList li {
        width: 222px;
        flex: 1 1 auto;
    }
}

@media tablet {
    .offersList {
        gap: 20px;
        margin-bottom: 20px;
    }

    .offers__section {
        height: 100%;
    }

    .offersTitle {
        margin-bottom: 34px;
    }

    .offersList__item {
        width: auto;
        height: 180px;
    }
}

@media mobile {
    .offersTitle {
        margin-left: 2px;
        margin-bottom: 28px;
        font-size: 1.25rem;
    }

    .offersList__item {
        min-width: 155px;
        width: 100%;
    }

    .offersList li {
        min-width: 155px;
        width: 47%;
    }

    .offersList__item:nth-child(3) {
        background: linear-gradient(black, black) padding-box,
        var(--primary-text-color) border-box;
        border: 1px solid transparent;
    }

    .offersList__item:nth-child(4),
    .offersList__item:nth-child(5) {
        background: linear-gradient(black, black) padding-box,
        var(--gradient) border-box;
        border: 1px solid transparent;
    }

    .offersList__item p {
        max-width: 80%;
        text-align: center;
        font-size: 1rem;
    }
}