@value mobile from "breakpoints.module.css";

.hireUsButton {
    display: flex;
    padding: 0.6rem 1.3rem;
    border-radius: 24px;
    background: linear-gradient(black, black) padding-box,
    var(--gradient) border-box;
    border: 1px solid transparent;
    text-transform: inherit;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.hireUsButtonText {
    background-image: var(--gradient);
    z-index: 1;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hireUsButton:hover {
    color: var(--primary-text-color);
    background: linear-gradient(black, black) padding-box,
    var(--primary-text-color) border-box;
    -webkit-text-fill-color: var(--primary-text-color);
}

.hireUsButton:hover .hireUsButtonText {
    background-image: var(--primary-text-color);
    -webkit-text-fill-color: var(--primary-text-color);
}


@media mobile {
    .hireUsButton {
        font-size: 1.1rem;
    }
}
