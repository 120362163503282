@value tablet, mobile from "breakpoints.module.css";

.hero {
    background-color: var(--black);
    color: var(--primary-text-color);
    overflow: hidden;
    height: 100%;
    position: relative;
}

.hero__content {
    display: flex;
    position: relative;
    height: 100%;
    align-items: center;
}

.text {
    max-width: 58%;
    z-index: 10;
}

.heading {
    font-size: 2.6rem;
    margin-bottom: 24px;
    white-space: pre-line;
}

.subheading {
    font-size: 1.3rem;
}

@media tablet {
    .hero__content {
        flex-direction: column;
        align-items: flex-start;
    }

    .text {
        padding-top: 13%;
        max-width: 100%;
        position: relative;
    }

    .heading {
        font-size: 2.2rem;
        margin-bottom: 0;
    }
}

@media mobile {

    .heading {
        font-size: 22px;
    }

    .heading_small {
        font-size: 1.5rem;
    }

    .noWrap {
        white-space: nowrap;
    }

    .subheading {
        font-size: 16px;
    }
}



