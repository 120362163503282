@value mobile from "breakpoints.module.css";

.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 38px 40px;
    border-top: 1px solid var(--primary-text-color);
    font-size: 0.8rem;
    max-height: 88px;
    text-align: center;
}


.footer__contactUsIcon {
    width: 32px;
    height: 32px;
    display: flex;
}

.footer__logo {
    display: flex;
    width: 13rem;
    height: 2.3rem;
    max-width: 134px;
}

.footer__contactInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.footer__contactIcons {
    display: flex;
    flex-direction: row;
    gap: 18px;
}

.footer__contactUsPhoneNumber {
    color: var(--primary-text-color);
    text-decoration: none;
    font-size: 0.8rem;
}

.phoneNumbers {
    display: flex;
    gap: 16px;
}


@media mobile {
    .footer {
        flex-direction: column;
        max-height: 100%;
        align-items: flex-start;
        padding: 48px 16px;
    }

    .footer__logo {
        margin-bottom: 28px;
    }

    .footer p {
        display: none;
    }

    .footer__contactInfo {
        align-items: flex-start;
    }

    .footer__contactInfo {
        gap: 25px;
    }

    .footer__contactUsPhoneNumber {
        font-size: 1rem;
    }

    .phoneNumbers {
        flex-direction: column;
    }
}