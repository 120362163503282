@value mobile from "breakpoints.module.css";

.hidden {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.8s ease;
}

.visible {
    opacity: 1;
    transform: translateY(0);
}


.hiddenTeam {
    opacity: 0;
    transform: translateY(-250px);
    transition: all 0.8s ease-in;
    transition-delay: 0.3s;
}

.visibleTeam {
    opacity: 1;
    transform: translateY(0);
}

@media mobile {
    .hiddenTeam {
        transform: translateY(-25vh);
    }

    .visibleTeam {
        opacity: 1;
        transform: translateY(0vh);
    }
}

