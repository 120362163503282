@value mobile from "breakpoints.module.css";

@font-face {
    font-family: 'Open Sans';
    src: url('./fonts/open-sans-regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

html {
    height: 100%;
    overflow: auto;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    height: 100%;
    min-height: 100vh;
    min-height: 100svh;
    font-size: 18px;
}

/* Color variables */
:root {
    --primary-text-color: #ffffff;
    --secondary-text-color: #000;
    --black: #000;
    --blue: #2562E8;
    --gradient: linear-gradient(0deg, rgba(37, 98, 232, 1) 35%, rgba(140, 0, 255, 1) 100%);
    --grey: #ACACAC;
    --red: #ED1919;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    img {
        width: 100%;
        height: auto;
    }
}


.app {
    background: var(--black);
    position: relative;
    color: var(--primary-text-color);
}

section {
    height: calc(100vh - 87px)
}

button {
    margin: 0;
    padding: 0;
    background: transparent;
    outline: none;
    border: none;
}

button:hover {
    outline: none;
}

h1, h2, h3, h4, h5 {
    font-weight: 400;
    margin: 0;
}

p {
    margin: 0;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.container {
    padding: 0 2.2rem;
    height: 100%;
}

@media mobile {
    body {
        font-size: 16px;
    }

    .container {
        padding: 0 1rem;
    }

    section {
        height: calc(100vh - 74px);
        height: calc(100svh - 74px);
    }
}

svg {
    width: 100%;
    height: auto;
}

[id*="loom"] {
    display: none !important;
}
